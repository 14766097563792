import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import "../assets/css/App.css";
import "../assets/css/index.css";

export default () => (
  <Layout>
    <SEO
      title="Commercial Power Flushing"
      description="Have an issue with your current heating system in your business? Contact BBIS commercial heating to learn more about how we can power flush your heating system"
      keywords="commercial power flushing, commercial power flushing london, commercial power flushing potters bar, commercial power flushing central london, commercial power flushing hertfordshire"
    />
    <Jumbotron imgClassName="img16 img-fluid" />
    <div class="white-segment">
      <h1 class="heading-color">Power flushing</h1>
      <p>
        Corrosive sludge and magnetite can sit in the bottom of radiators. We
        have a variety of ways of removing the sludge from the system, the most
        commonly known method is a Power flush. There are in fact other methods
        we use when power flushing is not suitable,(due to the age of the
        pipework for instance). Magnetic filters can also be used in conjunction
        with a power flush machine to clean the system. We can also use a PH
        neutral solution rather than the more aggressive acid solutions.
      </p>
      <p>
        Typical indicators that your system needs flushing, or cleansing, may be
        the following;
      </p>
      <ul>
        <li>A noisy heating system</li>
        <li>Radiators that take a long time to heat up</li>
        <li>
          Radiators that have cold patches / the bottom of the radiators are
          cold
        </li>
        <li>Leaks on the system, especially on the radiators</li>
      </ul>
      <p>
        A Power flush works by circulating an acid solution through the system
        by means of a machine, the dirt and sludge is then broken down and can
        be removed from the system.{" "}
      </p>
      <p>
        We would then treat the system with a corrosion inhibitor to stop the
        sludge being able to return again.
      </p>
      <p>
        We would also advise that a magnetic filter (e.g Magnaclean) be fitted
        to the system for extra piece of mind.
      </p>
      <p>
        Clean sytems work better, warm up quicker, are more energy efficient and
        prevent unnecessary breakdowns to your heating and hot water systems.
      </p>
      <p>
        We can flush all systems from high rise blocks (running at high
        pressures), to Grade 1 listed buildings (where the need for caution and
        care are paramount).
      </p>
      <p>
        For a professional heating service contact us today on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
    </div>
  </Layout>
);
